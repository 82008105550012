import { Injectable, Output, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { config } from 'process';
import { GlobalVariable } from '../global';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { Globals } from '../_models/globals';
import { AuthenticationService } from './authentication.service';




@Injectable()
export class DataService {
  hasNavigated: boolean = false;
  currentUser: User;
  serverName: string = "";
  serverID: string = "";
  serverType: string = "";
  constructor(private http: HttpClient, private router: Router, private globals: Globals, private authenticationService: AuthenticationService) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
  }
  public GetUsers() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.get<any>(GlobalVariable.BASE_API_URL + `UserController/GetUsers`, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public GetAdministrators() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.get<any>(GlobalVariable.BASE_API_URL + `UserController/GetAdministrators`, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public SetAdministrator(userUUID: string, isAdministrator: boolean) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `UserController/SetAdministrator`, { userUUID, isAdministrator }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public AddEmailServer(serverName: string, randomString: string) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `EmailController/AddEmailServer`, { serverName, randomString }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public AddTokensToEmailServer(randomString: string) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `EmailController/AddTokensToEmailServer`, { randomString }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public GetEmailServers() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.get<any>(GlobalVariable.BASE_API_URL + `EmailController/GetEmailServers`, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public GetEmailServerTokens(emailServerID: string) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `EmailController/GetEmailServerTokens`, { emailServerID }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public UpdateServerStatus(emailServerID: string, isEnabled: boolean) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `EmailController/UpdateServerStatus`, { emailServerID, isEnabled }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public ResendEmail(emailID: number) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `EmailController/ResendEmail`, { emailID }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public SendEmail(recipient: string, title: string, message: string, attachment: string, attachmentName: string, token: string) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `EmailController/SendEmail`, { recipient, title, message, attachment, attachmentName, token }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public GetEmails(serverName: string, emailServerID: number) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `EmailController/GetEmails`, { serverName, emailServerID }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public GetEmail(emailID: number) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `EmailController/GetEmail`, { emailID }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public DeleteEmail(emailID: number, attachment: string) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `EmailController/DeleteEmail`, { emailID, attachment }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
  public DeleteEmails(serverName: string, emailServerID: number) {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.post<any>(GlobalVariable.BASE_API_URL + `EmailController/DeleteEmails`, { serverName, emailServerID }, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }

  public GetFile() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.get<any>(GlobalVariable.BASE_API_URL + `TimesheetController/Index`, { headers: reqHeader })
      .pipe(map(data => {
        debugger;
        if (data) {
          debugger;
          alert(data);
        }
        debugger;
        return data;
      }));
  }

  public GetProjects() {
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.get<any>(GlobalVariable.BASE_API_URL + `ProjectsController/Index`, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }

  public SendEmails() {
    debugger;
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser.token });
    return this.http.get<any>(GlobalVariable.BASE_API_URL + `InternalOrdersController/Index`, { headers: reqHeader })
      .pipe(map(data => {
        if (data) {
        }
        return data;
      }));
  }
}
