import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-internal-orders',
  templateUrl: './internal-orders.component.html',
  styleUrls: ['./internal-orders.component.css']
})
export class InternalOrdersComponent implements OnInit {

  constructor(private Data: DataService, private route: Router) {

  }

  ngOnInit() {
    this.SendEmails();

  }

  SendEmails() {
    this.Data.SendEmails().subscribe(data => {
      if (data = true) {
        alert("Internal Orders emails have been sent successfully!");
        this.route.navigate(['/home']); 
      }
      else {
        alert("Error Sending Internal Orders Email!")
      }
    });
  }

}
