import { Component, enableProdMode, OnInit, EventEmitter, Output } from '@angular/core';
import { DataService } from '../services/data.service';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Globals } from '../_models/globals';
import { User } from '../_models/user';




@Component({
  selector: 'app-manage-servers',
  templateUrl: './manage-servers.component.html',
  styleUrls: ['./manage-servers.component.css'],
})

export class ManageServersComponent implements OnInit {
  emailServers;
  tokens: any;
  serverName: string;
  randomString: string;
  BasicEmailServerForm: FormGroup;
  popupVisible: boolean = false;
  popupTokenVisible: boolean = false;
  currentUser: User;
  constructor(private Data: DataService, private authenticationService: AuthenticationService, private router: Router, private formBuilder: FormBuilder, private http: HttpClient, private globals: Globals) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
    this.BasicEmailServerForm = this.formBuilder.group({
      Server_Name: ['', Validators.required],

    });
  }


  ngOnInit() {
    this.GetData();
  }
  GetData() {
    this.Data.GetEmailServers().subscribe(data => {
      this.emailServers = data
    });
  }
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'add',
        text: 'Add Mailing Server',
        onClick: this.AddMailingServer.bind(this)
      }
    });
  }
  AddMailingServer() {
    this.popupVisible = true;
  }
  Cancel() {
    this.popupVisible = false;
    this.BasicEmailServerForm.reset();
    this.popupTokenVisible = false;
  }
  onRowUpdating(e) {
    this.Data.UpdateServerStatus(e.oldData.emailServerID, e.newData.isEnabled).subscribe(data => {
      this.GetData();
    });
  }

  get f() { return this.BasicEmailServerForm.controls; }

  onSubmit() {
    if (this.BasicEmailServerForm.invalid) { return; }
    this.CreateRandomString(20);
    this.Data.AddEmailServer(this.f.Server_Name.value, this.randomString).subscribe(data => {
      if (data == true) {
        this.Data.AddTokensToEmailServer(this.randomString).subscribe(data => {
          if (data == true) {
            this.globals.ToastSuccessMessage("Successfully added new Email Server!");
            this.GetData();
          }
          else {
            this.globals.ToastErrorMessage("Error adding new Email Server");
            this.GetData();

          }
        }, error => {
            this.globals.ToastErrorMessage("Error adding new Email Server");
            this.GetData();

        });
      }
    });
    this.popupVisible = false;
    this.BasicEmailServerForm.reset();
  }
  CreateRandomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    this.randomString = result;
  }
  ViewTokens(data) {
    this.serverName = data.emailServerName;
    this.Data.GetEmailServerTokens(data.emailServerID).subscribe(data => {
      this.tokens = data;
    });
    this.popupTokenVisible = true;

  }
}
