import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private authenticationService: AuthenticationService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    //this will check if there is a user logged in already.
    if (currentUser) {
      return true;
    }

    // not logged in so redirect to login page with the return url

    //this one is to reroute to login page.
    //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

    return false;
  }
}
