import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../_models/user';
import { Router } from '@angular/router';
import { Globals } from '../_models/globals';
import { DataService } from '../services/data.service';
import { HttpClient } from '@angular/common/http';
import notify from 'devextreme/ui/notify';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  currentUser: User;
  emailServers;
  constructor(private authenticationService: AuthenticationService, private router: Router, private globals: Globals, private Data: DataService, private httpClient: HttpClient) {
    //this will always look out the session from the service and assign it to the class.
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
  }
  ngOnInit() {
    this.GetData();
  }
  GetData() {
    this.Data.GetEmailServers().subscribe(data => {
      this.emailServers = data;
    });
  }
  Test217(server) {
    this.Data.serverID = server.emailServerID;
    this.Data.serverName = server.emailServerName;
    this.Data.serverType = "Test Server 217";
    this.router.navigate(['/view-emails']);
  }
  Test248(server) {
    this.Data.serverID = server.emailServerID;
    this.Data.serverName = server.emailServerName;
    this.Data.serverType = "Test Server 248";
    this.router.navigate(['/view-emails']);
  }
  Live200(server) {
    this.Data.serverID = server.emailServerID;
    this.Data.serverName = server.emailServerName;
    this.Data.serverType = "Live Server 200";
    this.router.navigate(['/view-emails']);
  }

}
