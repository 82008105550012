import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../_models/user';
import { Router } from '@angular/router';



@Component({
  selector: 'app-timesheet-report',
  templateUrl: './timesheet-report.component.html',
  styleUrls: ['./timesheet-report.component.css']
})
export class TimesheetReportComponent implements OnInit {


  constructor(private Data: DataService, private route: Router) {

  }

  ngOnInit() {
    this.GetFile();

  }

  GetFile() {
    debugger;
    this.Data.GetFile().subscribe(data => {
      alert(data);
      this.route.navigate(['/home']);
    });
  }

}
