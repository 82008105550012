import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-projects',
  template: '<h3>"{{name}}"</h3>',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  @Input() number: number;
 
  @Input()
  get name(): string { return this._name; }
  set name(name: string) {
    this._name = (name && name.trim()) || '<no name set>';
  }
  private _name = '';


  constructor(private Data: DataService, private route: Router) {

  }

  
  ngOnInit() {
    this.GetFile();

  }

  GetFile() {
    this.Data.GetProjects().subscribe(data => {
      if (data = true) {
        alert("Email has been sent successfully!");
        this.route.navigate(['/home']); 
      }
      else {
        alert("Error Sending Email!")
      }

    });
  }

  
}
