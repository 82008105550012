import { Component, enableProdMode, OnInit, EventEmitter, Output } from '@angular/core';
import { DataService } from '../services/data.service';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Globals } from '../_models/globals';
import { User } from '../_models/user';




@Component({
  selector: 'app-assign-administrator',
  templateUrl: './assign-administrator.component.html',
  styleUrls: ['./assign-administrator.component.css'],
})

export class AssignAdministratorComponent implements OnInit {
  dataSource;
  administrators;
  advertismentID: number;
  userDropDown: FormGroup;
  roleDropDown: FormGroup;
  confirmAssign: boolean = false;
  userList: User[];
  selectedUserUUID = "0";
  submitted = false;
  userName: string = "";
  currentUser: User;
  constructor(private Data: DataService, private authenticationService: AuthenticationService, private router: Router, private formBuilder: FormBuilder, private http: HttpClient, private globals: Globals) {
    this.userDropDown = formBuilder.group({ 'uuid': [null] });
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
  }


  ngOnInit() {
    this.GetData();
  }
  GetData() {
    this.Data.GetUsers().subscribe(data => {
      this.dataSource = data
    });
    this.Data.GetAdministrators().subscribe(data => {
      this.administrators = data
    });
  }

  AssignAdmin() {
    this.Data.SetAdministrator(this.selectedUserUUID, true).subscribe(data => {
      this.GetData();
      this.globals.ToastSuccessMessage("Successfully Assigned Administrator!");

    }, error => {
      console.log(error);
        this.globals.ToastErrorMessage("Error Assigning Administrator!");
    });
    this.selectedUserUUID = "";
  
  }
  onRowUpdating(e) {
    if (e.newData.isAdministrator == true) {
      this.Data.SetAdministrator(e.oldData.userUUID, false).subscribe(data => {
        this.GetData();
        this.globals.ToastSuccessMessage("Successfully Demoted Administrators!");
      }, error => {
        console.log(error);
        this.globals.ToastErrorMessage("Error Demoting Administrators!");
      });
    }
  }

  UserChanged(paInfo) {
    if (paInfo.value!=null) {
      this.selectedUserUUID = paInfo.value.userUUID;
      this.userName = paInfo.value.name;
    }
    else {
      this.selectedUserUUID = "";
      this.userName ="";
    }
  }
}
