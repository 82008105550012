import { Component, enableProdMode, OnInit, EventEmitter, Output } from '@angular/core';
import { DataService } from '../services/data.service';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { from } from 'rxjs';
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { Globals } from '../_models/globals';
import { User } from '../_models/user';
import { Email } from '../_models/Email';




@Component({
  selector: 'app-view-emails',
  templateUrl: './view-emails.component.html',
  styleUrls: ['./view-emails.component.css'],
})

export class ViewEmailsComponent implements OnInit {
  serverName: string = "";
  serverID: string = "";
  serverType: string = "";
  emails: Email[] = [];
  email: Email = null;
  selectedFiles: File = null;
  currentUser: User;
  fileString: string = "";
  selectedImage: string = "";
  recipient: string = "";
  title: string = "";
  message: string = "";
  token: string = "";
  loadingVisible: boolean=true;
  popupVisible = false;
  confirmRemoveAllPopup = false;
  attachmentName: string = "";
  constructor(private Data: DataService, private authenticationService: AuthenticationService, private router: Router, private formBuilder: FormBuilder, private http: HttpClient, private globals: Globals) {
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
    this.serverID = this.Data.serverID;
    this.serverName = this.Data.serverName;
    this.serverType = this.Data.serverType;
    if (this.serverID == "" || this.serverName == "" || this.serverType == "") {
      this.router.navigate(['/home']);
    }
  }
  ngOnInit() {
    this.GetData();
  }
  GetData() {
    this.loadingVisible = true;
    this.Data.GetEmails(this.serverType, Number(this.serverID)).subscribe(data => {
      this.emails = data as Email[];
      this.email = this.emails[0];
      this.loadingVisible = false;
      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i].attachmentName == "" || typeof this.emails[i].attachmentName == "undefined" || this.emails[i].attachmentName == null) {
          this.emails[i].attachmentName = "No Attachment";
        }
      }
    });
  }
  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'trash',
        text: 'Clear Emails',
        onClick: this.ConfirmRemoveAll.bind(this)
      }
    });
    e.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: 'refresh',
        onClick: this.GetData.bind(this)
      }
    });
  }
  ConfirmRemoveAll() {
    this.confirmRemoveAllPopup = true;
  }
  RemoveAll() {
    this.Data.DeleteEmails(this.serverType, Number(this.serverID)).subscribe(data => {
      if (data) {
        this.globals.ToastSuccessMessage("Successfully Deleted Emails!");
      }
      else {
        this.globals.ToastErrorMessage("Error Deleting Emails!");
      }
      this.GetData();
    }, error => {
      this.globals.ToastErrorMessage("Error Deleting Emails!");
    });
  }
  displayFile(event) {
    this.recipient = "riyaardh.adam@s4.co.za";
    this.title = "Testing";
    this.message = "Message Body";
    this.token = "s4qiPaopunbpHfpGX9yp";
    this.attachmentName = event.target.files[0].name;
    const file = event.target.files[0];
    var loReader = new FileReader();
    loReader.onloadend = this.HandleReaderLoaded.bind(this);
    loReader.readAsDataURL(file);

  }
  HandleReaderLoaded(e) {
    let loReader = e.target;
    var loBase64result = loReader.result.substr(loReader.result.indexOf(',') + 1);
    this.Data.SendEmail(this.recipient, this.title, this.message, loBase64result, this.attachmentName, this.token).subscribe(data => {
      console.log(data);
    });

  }
  ResendEmail(data) {
    this.popupVisible = false;
    this.Data.ResendEmail(data.emailID).subscribe(data => {
      if (data == "Email Successfully Sent!") {
        this.globals.ToastSuccessMessage(data);
      }
      else {
        this.globals.ToastErrorMessage(data);
      }

      this.popupVisible = false;

    }, error => {
      this.globals.ToastErrorMessage(data);

    });
  }
  DeleteEmail(data) {
    this.Data.DeleteEmail(data.emailID, data.attachment).subscribe(data => {
      this.globals.ToastSuccessMessage("Successfully Deleted Email!");
      this.GetData();
    }, error => {
      this.globals.ToastErrorMessage("Error Deleting Email!");
    });
  }
  ViewEmail(data) {
    this.Data.GetEmail(data.emailID).subscribe(data => {
      this.email = data as Email;
      if (this.email.attachmentName == "" || typeof this.email.attachmentName == "undefined" || this.email.attachmentName == null) {
        this.email.attachmentName = "No Attachment";
      }
    });
    this.popupVisible = true;
  }
  Cancel() {
    this.popupVisible = false;
    this.confirmRemoveAllPopup = false;
  }




}
