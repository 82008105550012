import { Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { config } from 'process';
import { GlobalVariable } from '../global';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { BehaviorSubject, Observable } from 'rxjs';




@Injectable()
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  reqHeader;

  
  public hasNavigated: boolean = false;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(login: string, password: string) {
    return this.http.post<any>(GlobalVariable.BASE_API_URL + 'UserController/Authenticate', { login, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token && user.isAdmin) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
        }

        return user;
      }));
  }


  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');

    this.hasNavigated = false;
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

}
