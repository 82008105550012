import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { User } from '../_models/user';
import { Router } from '@angular/router';
import { Globals } from '../_models/globals';

@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {

  //this will store the user information.
  currentUser: User;
  companyName: string;
  userProfileImage: string;


  constructor(private authenticationService: AuthenticationService, private router: Router, private globals: Globals) {
    //this will always look out the session from the service and assign it to the class.
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;

      if (x != undefined) {
        if (x.profileImageID != undefined) {
          this.userProfileImage = this.globals.imageProfileURLDir + x.profileImageID + '.jpg';
        }
        else {
          this.userProfileImage ="../../assets/dist/img/default_user.png"
        }
      }

    });
  }

  ngOnInit() {

  }

  onLogout() {

    this.userProfileImage = undefined;
    this.authenticationService.logout();
  }

  login() {
    this.router.navigate(['/login']);
  }
}
