import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppSideMenuComponent } from './app-side-menu/app-side-menu.component';
import { SettingsComponent } from './settings/settings.component';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from './services/authentication.service';
import { Globals } from './_models/globals';
import { CommonModule, PercentPipe, DatePipe } from '@angular/common';
import { AuthGuard } from './_guards/auth.guard';
import { DxTabPanelModule, DxTreeViewModule, DxTemplateModule, DxPopupModule, DxDataGridModule, DxButtonModule, DxPopoverModule, DxGalleryModule, DxTabsModule, DxSelectBoxModule, DxCheckBoxModule, DxScrollViewModule, DxDropDownBoxModule, DxTreeViewComponent, DxAccordionModule, DxSliderModule, DxTagBoxModule, DxLoadPanelModule } from 'devextreme-angular';
import { DataService } from './services/data.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AssignAdministratorComponent } from './assign-administrator/assign-administrator.component';
import { ManageServersComponent } from './manage-servers/manage-servers.component';
import { ViewEmailsComponent } from './view-emails/view-emails.component';
import { TimesheetReportComponent } from './timesheet-report/timesheet-report.component';
import { InternalOrdersComponent } from './internal-orders/internal-orders.component';
import { ProjectsComponent } from './projects/projects.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AppHeaderComponent,
    AppSideMenuComponent,
    SettingsComponent,
    LoginComponent,
    AssignAdministratorComponent,
    ManageServersComponent,
    ViewEmailsComponent,
    TimesheetReportComponent,
    InternalOrdersComponent,
    ProjectsComponent,



  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    DxTabPanelModule,
    DxTreeViewModule,
    DxTemplateModule,
    DxPopupModule,
    DxDataGridModule,
    DxButtonModule,
    FormsModule,
    DxPopoverModule,
    ReactiveFormsModule,
    DxGalleryModule,
    DxTabsModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxScrollViewModule,
    NgbModule,
    DxDropDownBoxModule,
    DxAccordionModule,
    DxSliderModule,
    DxTagBoxModule,
    DxLoadPanelModule,
    RouterModule.forRoot([
      { path: '', component: LoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'home', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'assign-administrator', component: AssignAdministratorComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'manage-servers', component: ManageServersComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'view-emails', component: ViewEmailsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'timesheet', component: TimesheetReportComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'timesheet-projects', component: TimesheetReportComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'internal-orders', component: InternalOrdersComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'projects', component: ProjectsComponent, pathMatch: 'full', canActivate: [AuthGuard] },


    ])
  ],
  //providers are used to setup the services that are going to be used in the angular project.
  providers: [
    AuthenticationService,
    Globals,
    DatePipe,
    AuthGuard,
    DataService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
